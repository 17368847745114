// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-intro-page-js": () => import("./../src/templates/intro-page.js" /* webpackChunkName: "component---src-templates-intro-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-artlab-post-js": () => import("./../src/templates/artlab-post.js" /* webpackChunkName: "component---src-templates-artlab-post-js" */),
  "component---src-templates-artlab-page-js": () => import("./../src/templates/artlab-page.js" /* webpackChunkName: "component---src-templates-artlab-page-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-work-post-js": () => import("./../src/templates/work-post.js" /* webpackChunkName: "component---src-templates-work-post-js" */),
  "component---src-templates-work-page-js": () => import("./../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artlab-js": () => import("./../src/pages/artlab.js" /* webpackChunkName: "component---src-pages-artlab-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

