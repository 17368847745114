import React, { createContext, useContext, useReducer } from "react";

const initialContext = {
  category: "",
  style: ""
};

// Reducer
const reducer = (store, action) => {
  switch (action.type) {
    case "pick-category":
      if (store.category === action.category) {
        action.category = "";
      }
      return {
        ...store,
        style: "",
        category: action.category
      };

    case "pick-style":
      if (store.style === action.style) {
        action.style = "";
      }
      return {
        ...store,
        category: "",
        style: action.style
      };

    default:
      console.error(`Unknown action type: ${action.type}`);
      return store;
  }
};

// Provider
export const FilterMenuContext = createContext();
export const FilterMenuProvider = ({ children }) => (
  <FilterMenuContext.Provider value={useReducer(reducer, initialContext)}>
    {children}
  </FilterMenuContext.Provider>
);
export const wrapRootElement = ({ element }) => (
  <FilterMenuProvider>{element}</FilterMenuProvider>
);

// Consumer Hook
const useFilterMenu = () => {
  const [store, dispatch] = useContext(FilterMenuContext);
  return {
    ...store,
    pickCategory: category => dispatch({ type: "pick-category", category }),
    pickStyle: style => dispatch({ type: "pick-style", style })
  };
};

export default useFilterMenu;

// FILTER AUX WORKS
export const filterNodeWorks = (workNodes, category, style) => {
  console.log(workNodes[11].node.frontmatter.cat_styles);
  if (!category && !style) {
    return workNodes;
  }
  return workNodes.filter(({ node: work }) => {
    let selected = true;
    if (category) {
      if (work.frontmatter.cat_categories) {
        selected = work.frontmatter.cat_categories[category];
        if ("press" === category) {
          selected = selected || work.frontmatter.cat_categories["books"];
        }
      } else {
        selected = false;
      }
    }
    if (style) {
      if (work.frontmatter.cat_styles) {
        selected = selected && work.frontmatter.cat_styles[style];
      } else {
        selected = selected && false;
      }
    }
    return selected;
  });
};
